<template>
  <b-form @submit.prevent="lookupCompanyDetails">
    <b-row>
      <b-col cols="12" md="4" lg="3">
        <b-form-group label="Company Number" :invalid-feedback="validationInvalidFeedback(errors, 'company_number')" :state="validationState(errors, 'company_number')">
          <b-form-input class="text-monospace" :disabled="isLoading || disabled" v-model="companyNumber" />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4" lg="3">
        <b-form-group label="Company Name" :invalid-feedback="validationInvalidFeedback(errors, 'company_name')" :state="validationState(errors, 'company_name')">
          <b-form-input class="text-monospace" :disabled="isLoading || disabled" v-model="companyName" />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4" lg="3">
        <b-form-group label="Trading As">
          <b-form-input class="text-monospace" :disabled="isLoading || disabled" v-model="tradingAs" />
        </b-form-group>
      </b-col>
      <b-col class="d-flex flex-row" cols="12" lg="3">
        <b-button block class="align-self-end mb-3" type="submit" variant="secondary"><b-spinner small v-if="isLoading" /><span v-if="!isLoading">Lookup Details</span></b-button>
      </b-col>
      <b-col class="mb-4" cols="12">
        <b-form-checkbox v-model="soleTrader">Company is a sole trader/joint partnership.</b-form-checkbox>
      </b-col>
      <b-col cols="12" v-if="noResults">
        <b-card>
          <b-card-text class="d-flex flex-row">
            <b-icon class="text-primary" icon="cloud-slash" font-scale="4"></b-icon>
            <div class="ml-4 flex-fill">
              <p class="font-weight-bold h5 mb-1">No Companies Found</p>
              <p class="mb-0">Try again later. If you know the company number, search by that. If both fields have been populated, company number takes precedence in the lookup.</p>
            </div>
          </b-card-text>
        </b-card>
      </b-col>
      <b-modal id="company-lookup-details" size="lg" title="Company Lookup Results"
        body-class="p-0" cancel-variant="dark" :ok-disabled="true" ok-variant="secondary">
        <b-list-group flush>
          <b-list-group-item button @click="setCompanyDetails(item.company_number, item.title, soleTrader, getCompanyAddress(item))" :key="item.company_number" v-for="item in data">
            <p class="font-weight-bold h5 mb-1 text-primary">{{ item.title }}</p>
            <p class="mb-0 text-muted"><small><span class="badge" :class="{'badge-success': item.company_status === 'active', 'badge-danger': item.company_status === 'dissolved'}" v-if="item.company_status">{{ item.company_status && item.company_status.toUpperCase() }}</span> {{ item.company_number || 'No Company No' }} &bull; {{ item.address_snippet }}</small></p>
          </b-list-group-item>
        </b-list-group>
      </b-modal>
    </b-row>
  </b-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import lookupModule from '../store/lookup'
import validationMixin from '../mixins/validation'

export default {
  beforeCreate () {
    if (!this.$store.hasModule('lookup')) {
      this.$store.registerModule('lookup', lookupModule)
    }
  },
  destroyed () {
    if (this.$store.hasModule('lookup')) {
      this.$store.unregisterModule('lookup')
    }
  },
  mixins: [validationMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Object
    },
    value: {
      type: Object,
      default: () => ({ company_name: '', company_number: '', address: '', sole_trader: false })
    }
  },
  computed: {
    ...mapGetters('lookup', ['data', 'isLoading']),
    companyName: {
      set (value) {
        const coName = (this.companyNameRaw && this.companyNameRaw.split(' T/a ')) || []

        if (coName.length > 1) {
          this.companyNameRaw = `${value} T/a ${coName[1]}`
        } else {
          this.companyNameRaw = value
        }
      },
      get () {
        return this.companyNameRaw && this.companyNameRaw.split(' T/a ')[0]
      }
    },
    tradingAs: {
      set (value) {
        const coName = (this.companyNameRaw && this.companyNameRaw.split(' T/a ')) || []

        if (value === '') {
          this.companyNameRaw = coName[0]
        } else {
          this.companyNameRaw = `${coName[0]} T/a ${value}`
        }
      },
      get () {
        return this.companyNameRaw && this.companyNameRaw.split(' T/a ')[1]
      }
    }
  },
  data () {
    return {
      noResults: false,
      soleTrader: this.value.sole_trader,
      companyNumber: this.value.company_number,
      companyNameRaw: this.value.company_name,
      address: this.value.address
    }
  },
  methods: {
    ...mapActions('lookup', ['companiesHouse']),
    lookupCompanyDetails () {
      this.companiesHouse(this.value).then(response => {
        this.noResults = false
        if (response.data.items.length === 1) {
          const company = response.data.items[0]
          this.setCompanyDetails(company.company_number, company.title, this.soleTrader, this.getCompanyAddress(company))
        } else if (response.data.items.length === 0) {
          this.noResults = true
        } else {
          this.$bvModal.show('company-lookup-details')
        }
      })
      this.setCompanyDetails(this.companyNumber, this.companyName, this.soleTrader, this.address)
    },
    getCompanyAddress (company) {
      return {
        address_line_1: [isNaN(parseInt(company.address.premises)) ? company.address.premises : company.address.premises + ' ' + company.address.address_line_1, isNaN(parseInt(company.address.premises)) && company.address.address_line_2 ? ', ' + company.address.address_line_1 : ''].filter(x => x).join(', '),
        address_line_2: [isNaN(parseInt(company.address.premises)) ? company.address.address_line_1 : '', company.address.address_line_2].filter(x => x).join(', '),
        city: company.address.locality,
        county: company.address.region,
        postcode: company.address.postal_code
      }
    },
    setCompanyDetails (number, name, soleTrader, address) {
      if (soleTrader !== this.value.sole_trader ||
          number !== this.value.company_number ||
          name !== this.value.company_name ||
          address !== this.value.address) {
        this.$emit('input', { company_number: number, company_name: name, sole_trader: !!soleTrader, address: address })
      }
      this.$bvModal.hide('company-lookup-details')
    },
    updateCompanyDetails () {
      this.setCompanyDetails(this.companyNumber, this.companyNameRaw, this.soleTrader, this.address)
    }
  },
  watch: {
    address () {
      this.updateCompanyDetails()
    },
    companyNumber () {
      this.updateCompanyDetails()
    },
    companyNameRaw () {
      this.updateCompanyDetails()
    },
    soleTrader () {
      this.updateCompanyDetails()
    },
    'value' (value) {
      this.companyNameRaw = value.company_name
      this.companyNumber = value.company_number
      this.soleTrader = value.sole_trader
      this.address = value.address
    }
  }
}
</script>
