import {
  COMPANIES_HOUSE_REQUEST,
  COMPANIES_HOUSE_SUCCESS,
  COMPANIES_HOUSE_FAILURE
} from './types'

export default {
  [COMPANIES_HOUSE_REQUEST] (state) {
    state.loading = true
  },
  [COMPANIES_HOUSE_SUCCESS] (state, { response }) {
    state.loading = false
    state.data = response.data.items
  },
  [COMPANIES_HOUSE_FAILURE] (state, { error }) {
    state.loading = false
    state.error = error
  }
}
