import {
  COMPANIES_HOUSE_REQUEST,
  COMPANIES_HOUSE_SUCCESS,
  COMPANIES_HOUSE_FAILURE
} from './types'

export default {
  companiesHouse ({ commit }, params) {
    commit(COMPANIES_HOUSE_REQUEST)
    return window.axios.get('/lookup/companiesHouse', { params }).then(response => {
      commit(COMPANIES_HOUSE_SUCCESS, { response })
      return response
    }).catch(error => {
      commit(COMPANIES_HOUSE_FAILURE, { error })
      throw error
    })
  }
}
